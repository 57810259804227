const Fields = Object.freeze({
    RECEIPT_NUMBER: "receiptNumber",
    INVOICE_ID: "invoiceId",
    SERIAL_NUMBER: "serialNumber",
    SIMPLIFIED_INVOICE_NUMBER: "simplifiedInvoiceNumber",
    PURCHASE_ID: "purchaseId",
    DATE: "date",
    FLIGHT_NUMBER: "flightNumber",
    TRAIN_NUMBER: "trainNumber",
    ISSUED_BY: "issuedBy",
    NIF: "nif",
    ADDRESS: "address"
});

module.exports = { Fields };
module.exports = {
    basic_auth_enabled: process.env.NODE_ENV === 'staging',
    basic_auth_username: process.env.basic_auth_username,
    basic_auth_password: process.env.basic_auth_password,
    company_code: process.env.company_code,
    core_oauth2_client_id: process.env.core_oauth2_client_id,
    core_oauth2_client_secret: process.env.core_oauth2_client_secret,
    core_oauth2_host: process.env.core_oauth2_host,
    core_url: process.env.core_url,
    company_name: process.env.company_name,
    company_logo_name: process.env.company_logo_name,
    company_logo_receipt_name: process.env.company_logo_receipt_name || process.env.company_logo_name,
    company_primary_color: process.env.company_primary_color,
    company_secondary_color: process.env.company_secondary_color,
    company_favicon: process.env.company_favicon,
    company_email: process.env.company_email,
    is_rounded_corners: process.env.is_rounded_corners === 'true',
    flight_number_prefix: process.env.flight_number_prefix,
    recaptcha_secret: process.env.recaptcha_secret,
    recaptcha_threshold: Number(process.env.recaptcha_threshold),
    receipt_simplified_invoice: process.env.receipt_simplified_invoice,
    receipt_issued_by: process.env.receipt_issued_by,
    receipt_nif: process.env.receipt_nif,
    receipt_address: process.env.receipt_address,
    enable_payment_methods: process.env.enable_payment_methods === 'true',
    company_type: process.env.company_type || 'air',
    composite_serial: process.env.composite_serial === 'true',
    show_disclaimer: process.env.show_disclaimer === 'true',
    invoice_id_active: (process.env.invoice_id_active || 'false') === 'true',
    seat_number_active: (process.env.seat_number_active || 'true') === 'true',
    receipt_template: (process.env.receipt_template || 'default'),
}